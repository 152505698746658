import type { NextPage } from 'next'
import Head from 'next/head'
import Pixel from 'common/seo/pixel'
import { Home } from 'src/views'

const HomePage: NextPage = () => {
  return (
    <>
      <Head>
        <meta charSet="UTF-8" />
        <title>Empréstimo pessoal rápido e simples - Crédito One</title>
        <meta
          name="description"
          content="Empréstimos em até 18x. Rápido e sem garantias. Faça uma simulação online e descubra a parcela ideal para você."
        />

        <meta property="og:locale" content="pt_BR" />
        <meta property="og:type" content="website" />
        <meta
          property="og:title"
          content="Empréstimo pessoal rápido e Simples - Crédito One"
        />
        <meta
          property="og:description"
          content="Empréstimos em até 18x. Rápido e sem garantias. Faça uma simulação online e descubra a parcela ideal para você."
        />
        <meta
          property="og:image"
          content="https://uploads-ssl.webflow.com/5fb18514177e609c5101c5b2/61aa78267b898128a13bbb31_open%20graph.png"
        />
        <link rel="icon" href="/favicon.ico" />
        {/* <Pixel /> */}
      </Head>
      <Home />
    </>
  )
}

export default HomePage
